import React from 'react';

import { Tabs } from 'antd';
import MainView from "./mainview";
import userinfo from "../logic/userinfo";
import browser from "./browser";

class TabView extends React.Component {
    constructor(props) {
        super(props);
        this.superEventNotify = props.eventNotify;
        const panes = [];
        this.state = {
            activeKey: null,
            panes,
            projectLanguage: props.language,
            // 页面配置
            showList:true,
            showCompile:true,
            showCode:true,
            showExplain:true,
        };
        this.eventList = []
        this.eventList.push(
            browser.onRenameFunction(
            (item) => {
                const {panes} = this.state;
                panes.forEach((pane) => {
                    if (pane.key == item.address) {
                        pane.title = item.name
                        pane.name = item.name
                    }
                })
                this.setState(panes)
            }))
        this.eventList.push(browser.onOpenFunction((item) => {
                this.add({title: item.name, key: item.address})
            }))
        this.eventList.push(browser.onOpenFile(()=>{
            this.setState({panes:[]})
        }))

    }

    componentWillUnmount(){
        // 移除事件
        browser.closeEvents(this.eventList)
    }

    componentDidUpdate(prevProps, prevState){
        if ( this.state.activeKey != prevState.activeKey)
            browser.emitActiveFunction(this.state.activeKey)
    }

    onChange = activeKey => {
        this.setState({ activeKey });
    };

    onEdit = (targetKey, action) => {
        this[action](targetKey);
    };

    add = ({title, key}) => {
        const { panes } = this.state;
        key = key.toString()
        if (!panes.find(obj => obj.key == key))
            panes.push({ title: title.substring(0, 16), key: key, name:title });
        this.setState({ panes, activeKey:key });
    };

    remove = targetKey => {
        let { activeKey } = this.state;
        let lastIndex;
        this.state.panes.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const panes = this.state.panes.filter(pane => pane.key !== targetKey);
        if (panes.length && activeKey === targetKey) {
            if (lastIndex >= 0) {
                activeKey = panes[lastIndex].key;
            } else {
                activeKey = panes[0].key;
            }
        }
        this.setState({ panes, activeKey });
    };

    onOpenFile = () =>{
        browser.emitOpenFileDialog()
    }

    render() {
        const projectLanguage = this.state.projectLanguage
        const toolbar =  (<></>)
        return (
            <Tabs
                hideAdd
                onChange={this.onChange}
                activeKey={this.state.activeKey}
                type="editable-card"
                tabBarExtraContent={toolbar}
                onEdit={this.onEdit}
                items={this.state.panes.map(pane => (
                    {
                        label: pane.title,
                        key: pane.key,
                        children:
                            <>{<MainView item={{ address: pane.key, name: pane.name }} language={projectLanguage}></MainView>}</>
                    }
                ))}
            />
        );
    }
}

export  default  TabView
