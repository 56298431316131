import MlmError from "../logic/mlmerror";
import {message} from 'antd';

export const showErrorMessage = function (error) {
    console.log(error)
    if (error instanceof MlmError) {
        if (error.status == 'busy') {
            message.error(error.message)
        } else
            message.error(error.message)
    } else if (error instanceof Error) {
        //message.error(error.message)
        message.error('Request failed, please try again')
    } else message.error(error?.toString())
}