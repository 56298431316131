import { useState, useRef, useEffect } from 'react'
import config from "../config";
import userInfo from "../logic/userinfo";
import browser from "./browser";
import reportLog from "../logic/reportLog";
import DasmClient from "../logic/dasmclient";
import { Modal, Button, Upload, message, Input,Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';
const { TextArea } = Input;

const dasmClient = new DasmClient()

const CloseSvg = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 4L12 12" stroke="#59636F" strokeLinecap="round" stroke-linejoin="round" />
    <path d="M4 12L12 4" stroke="#59636F" strokeLinecap="round" stroke-linejoin="round" />
</svg>
const GantanSvg = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z" stroke="#869CB0" stroke-width="2" stroke-linejoin="round" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5597 12.6904 16 12 16C11.3097 16 10.75 16.5597 10.75 17.25C10.75 17.9404 11.3097 18.5 12 18.5Z" fill="#869CB0" />
    <path d="M12 6V14" stroke="#869CB0" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
</svg>

const FeedBack = (props) => {

    const { language: localLanguage } = props
    const [show, setShow] = useState(false)
    const [activeTag, setActiveTag] = useState('')

    const betterThan = localLanguage === 'en' ? "Is the result better than last time?" : "本次结果比上次更好吗？"
    const Better = localLanguage === 'en' ? "Better" : "更好"
    const Worse = localLanguage === 'en' ? "Worse" : "更差"
    const Similar = localLanguage === 'en' ? "Similar" : "差不多"

    useEffect(()=>{
        if (props.runTimes > 1) {   
            setShow(true)
            setActiveTag('')
        }
    }, [props.runTimes])
    
    const feedback = async (tag,value) => {
        if (activeTag) {
            return
        }
        if (!props?.sign.c_code) {
            message.success(localLanguage === 'en' ? `successfully` : `提交成功`)
            setActiveTag(tag)
            setShow(false)
            return
        }
        const address = props.address

        const comment = {
            id: props.fileId,
            sig: props.sign.c_code,
            comment: tag,
            score:value
        }
        dasmClient.userComment(address, comment).then((res) => {
            if (res.result) {
                message.success(localLanguage === 'en' ? `successfully` : `提交成功`)
                setActiveTag(tag)
                setShow(false)
            }
        }).catch((err) => {
            message.error(localLanguage === 'en' ? `Interface error` : `接口错误`)
        })
        
    }
    return show && <div className="feedback-container" >
        <div className="mlm-better-than">{betterThan}</div>
        <div className="mlm-better-than-button">
            <div onClick={() => feedback("Batter", 1)} className={`mlm-button ${activeTag === 'Batter' ? 'mlm-button-active' : ''} ${activeTag !== '' ? 'disabled' : ''}`}>{Better}</div>
            <div onClick={() => feedback("Worse", -1)} className={`mlm-button ${activeTag === 'Worse' ? 'mlm-button-active' : ''} ${activeTag !== '' ? 'disabled' : ''}`}>{Worse}</div>
            <div onClick={() => feedback("Similar", 0)} className={`mlm-button ${activeTag === 'Similar' ? 'mlm-button-active' : ''} ${activeTag !== '' ? 'disabled':''}`}>{Similar}</div>
            <div className="mlm-close-button" onClick={()=>setShow(false)}>
                <CloseSvg />
            </div>
        </div>
    </div>
}

const FeedbackModalButton = (props) => {
    const { language: localLanguage } = props

    const [feedbackModal, setFeedbackModal] = useState(false)
    const [feedbackInfo, setFeedbackInfo] = useState()
    const [imageUrl, setImageUrl] = useState()
    const [open, setOpen] = useState(false);

    const [disabled, setDisabled] = useState(true);
    const draggleRef = useRef(null);

    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      });

    const submit = async () => {
        console.log(feedbackInfo, imageUrl)
        // 提交反馈接口
        if (!props?.sign.c_code) {
            message.success(localLanguage === 'en' ? `successfully` : `提交成功`)
            setFeedbackModal(false)
            return
        }
        const address = props.address

        const comment = {
            id: dasmClient.file,
            sig: props.sign.c_code,
            comment: feedbackInfo,
            // score:1
        }
        await dasmClient.userComment(address, comment).then((res) => {
            if (res.result) {
                message.success(localLanguage === 'en' ? `successfully` : `提交成功`)
                setFeedbackModal(false)
            }
        }).catch((err) => {
            message.error(localLanguage === 'en' ? `Interface error` : `接口错误`)
        })
        
    }
    const closeModel = () => {
        setFeedbackModal(false)
    }

    const onItemClick = (uuid) => {
        browser.file = uuid
        browser.emitOpenFile(uuid)
    }
    const fileProps = {
        name: 'file',

        action: config.baseUrl + '/' + config.api.fileUpload,
        headers: {
            Authorization: userInfo.idToken,
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                if (info.file.response.status === 'ok') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    onItemClick(info.file.response.data.binary_uuid)

                    reportLog.logUploadFile(info.file.response.data.binary_uuid, info.file.name, info.file.size)


                    setImageUrl(info.file)
                }
                else
                    message.error(info.file.response.error.toString())
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('You can only upload JPG/PNG file!');
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('Image must smaller than 2MB!');
            }
            return isJpgOrPng && isLt2M;
        }
    };

    const onChange = (e) => {
        setFeedbackInfo(e.target.value)
    };

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
          return;
        }
        setBounds({
          left: -targetRect.left + uiData.x,
          right: clientWidth - (targetRect.right - uiData.x),
          top: -targetRect.top + uiData.y,
          bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
      };

    const feu = localLanguage === 'en' ? "Feedback to technicians" : "反馈给技术人员"
    return <>
        <Tooltip placement="top" title={feu} >
            <div className="feedback-modal-button button-active" onClick={() => setFeedbackModal(true)}>
                <GantanSvg />
            </div>
        </Tooltip>

        <Modal
            open={feedbackModal}
            onOk={submit}
            okText={localLanguage === "en" ? "Submit" : "提交"}
            onCancel={closeModel}
            width="446px"
            centered
            mask={false}
            classNames={{ wrapper: 'feedback-modal' }}
            onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            modalRender={(modal) => (
                <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    nodeRef={draggleRef}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
            title={
                <div
                  style={{
                    width: '100%',
                    cursor: 'move',
                  }}
                  onMouseOver={() => {
                    if (disabled) {
                      setDisabled(false);
                    }
                  }}
                  onMouseOut={() => {
                    setDisabled(true);
                  }}
                  // fix eslintjsx-a11y/mouse-events-have-key-events
                  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                  onFocus={() => {}}
                  onBlur={() => {}}
                  // end
                >
                    {localLanguage === "en" ? "User feedback" : "用户反馈"}
                </div>
              }
        >
            <div className="feedback-box" style={{ display: 'flex', flexDirection: 'column' }}>
                {localLanguage === "en" ? "Please enter product function questions and suggestions for improvement." : "请输入产品功能问题及改进建议"}
                <TextArea rows={4} onChange={(e) => onChange(e)} placeholder={localLanguage === "en" ?"Please enter your comments or suggestions":"请输入您的意见/建议"} maxLength={200} showCount />
                <div style={{color:'#9497A4'}}>
                    {/* <Upload {...fileProps}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    支持上传1张图片，格式为png、jpg、jpeg、3 M以内 */}
                    {localLanguage === "en" ? "More questions or suggestions can be given by email: mlm@01.ai" : "更多问题/建议可通过邮箱反馈：mlm@01.ai"}
                </div>
            </div>

        </Modal>
    </>
}

export default function fb(params) {
    return <div style={{ marginBottom:"16px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        <FeedbackModalButton {...params} />
        <FeedBack {...params} />
    </div>
}