import SyntaxHighlighter from 'react-syntax-highlighter';
import { useEffect, useRef } from 'react';
// import { atelierLakesideDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import config from '../config';
import browser from "./browser";
import BaseAtelierLakesideMin from '../theme/atelier-lakeside-min'
import BaseAtelierLakeside from '../theme/atelier-lakeside'

export default function CodeViewer({ codeString, language,projectLanguage }) {
    const dom = useRef(null);

    useEffect(()=>{
        console.log('CodeViewer mount ' + language)
        const events = browser.onCCodeDone((address)=>{
            if (dom != null && dom.current != null)
                 dfs(dom.current)
        })
        return function componentWillUnmount(){
            console.log('CodeViewer unmount ' + language)
            browser.closeEvents([events])
        }
    },[])/* dep = [] 当前 effect 没有任何依赖项，也就只有初始化执行一次*/

    function handleFunctionClick(funItem) {
        browser.emitOpenFunction(funItem);
    }

    function function_address(name) {
        name = name.trim();
        if (name in config.funName2Addr)
            return config.funName2Addr[name];
        return null;
    }

    // 遍历字符串。是否命中函数列表，命中的字符串，增加a跳转
    function dfs(node) {
        if (node instanceof HTMLElement) {
            node.childNodes.forEach((item) => {
                dfs(item);
            });
        }
        if (node instanceof Text) {
            if (node.textContent.trim()) {
                let parent = node.parentNode;
                if (parent.nodeName != 'A') {
                    let funItem = function_address(node.textContent);
                    if (funItem != null) {
                        let a = node.ownerDocument.createElement('a');
                        a.appendChild(node.cloneNode(true));
                        a.addEventListener('click', () => handleFunctionClick(funItem));
                        a.href = 'javascript:void(0);';
                        parent.insertBefore(a, node);
                        node.remove();
                    }
                }
            }
        }
    }

    const LoadingText = projectLanguage === "en" ? "Loading..." : "Loading..."
    if (codeString == null)
        return <div align={'center'}>{LoadingText}</div>;
    else
        return (
            <div ref={dom}>
                <SyntaxHighlighter
                    language={language}
                    style={BaseAtelierLakeside}
                    showLineNumbers={true}
                >
                    {codeString}
                </SyntaxHighlighter>
            </div>
        );
}
