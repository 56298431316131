import React, { useState,useRef } from 'react';
import { Modal,Button } from 'antd';

import homeData from './homeData'
import "./Home.css"
import { useEffect } from 'react';
import VUL337 from "./VUL337.png"

import './i18n'
import { useTranslation } from 'react-i18next'
import { store as localStore } from './util/store'
import { getBrowserLang } from './util/languageUtil.js'
import reportLog from "./logic/reportLog";
import { useGuard } from "@authing/guard-react18";
const LogoSvg = () => <svg width="72" height="24" viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.888 0.575998H24.016V24H18.832V10.112L13.552 17.664H10.768L5.424 10.048V24H0.304V0.575998H4.528L12.24 11.872L19.888 0.575998ZM66.9193 0.575998H71.0473V24H65.8633V10.112L60.5833 17.664H57.7993L52.4553 10.048V24H47.3353V0.575998H51.5593L59.2713 11.872L66.9193 0.575998Z" fill="white" />
    <path d="M28.5853 0.575998H34.0573V19.136H44.3293V24H28.5853V0.575998Z" fill="#00FF25" />
</svg>

const SanjiaoSvg = () => <svg width="15" height="30" viewBox="0 0 15 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.142044 0.930741L14.2842 15.0729L0.142044 29.215V0.930741Z" fill="#00FF25" />
</svg>

const BingoSvg = () => <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.49753 16.3134L2.88142 9.95592C2.56483 9.6518 2.13551 9.48096 1.68785 9.48096C1.2402 9.48096 0.810873 9.6518 0.494286 9.95592C0.177795 10.2601 0 10.6727 0 11.1028C0 11.533 0.177795 11.9455 0.494286 12.2497L7.1104 18.6072C7.42395 18.9086 7.79622 19.1477 8.20596 19.3108C8.6157 19.4739 9.05486 19.5579 9.49838 19.5579C9.94189 19.5579 10.3811 19.4739 10.7908 19.3108C11.2005 19.1477 11.5728 18.9086 11.8864 18.6072L27.5057 3.59848C27.8222 3.29427 28 2.88173 28 2.45158C28 2.02142 27.8222 1.60888 27.5057 1.30467C27.1891 1.00056 26.7598 0.829712 26.3121 0.829712C25.8645 0.829712 25.4352 1.00056 25.1186 1.30467L9.49753 16.3134Z" fill="#00FF25" />
</svg>

const JiantouSvg = () => <svg width="16" height="85" viewBox="0 0 16 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.29289 84.7071C7.68341 85.0976 8.31658 85.0976 8.7071 84.7071L15.0711 78.3431C15.4616 77.9526 15.4616 77.3195 15.0711 76.9289C14.6805 76.5384 14.0474 76.5384 13.6569 76.9289L8 82.5858L2.34314 76.9289C1.95262 76.5384 1.31945 76.5384 0.928929 76.9289C0.538404 77.3195 0.538404 77.9526 0.928929 78.3431L7.29289 84.7071ZM7 -4.37114e-08L7 84L9 84L9 4.37114e-08L7 -4.37114e-08Z" fill="#00FF25" />
</svg>

const VULLogoSvg = () => <img src={VUL337} alt="" style={{ width: "167px", height: "85px" }} />
const TruBottonSvg = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 5.5H18.5V14.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.77197 18.2279L18.4999 5.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const isUserMobile = () => {
    if(typeof navigator !== 'undefined') {
      const ua = navigator && navigator.userAgent.toLowerCase()
      // return /mobile|android|iphone|ipod|phone|ipad/i.test(ua)
      return /mobile|android|iphone|ipod|phone/i.test(ua)
    }
    return false
  }


const MLMSvg = () => <svg width="304" height="59" viewBox="0 0 304 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M165.761 0H174.561V58.56H163.521V20.8L148.641 42.96H142.561L127.601 20.64V58.56H116.641V0H125.841L145.841 30.32L165.761 0Z" fill="white" />
    <path d="M70.2344 0H81.8344V48.16H108.474V58.56H70.2344V0Z" fill="#00FF25" />
    <path d="M49.12 0H57.92V58.56H46.88V20.8L32 42.96H25.92L10.96 20.64V58.56H0V0H9.2L29.2 30.32L49.12 0Z" fill="white" />
    <path d="M303.03 56.1159V58.5599H276.71V56.1159L288.319 25.9419H291.515L303.03 56.1159ZM300.68 56.4449L290.152 28.0569H289.682L278.919 56.4449H300.68Z" fill="white" />
    <path d="M272.997 36.7989H264.819V58.5599H262.845V36.7989H254.667V34.8249H262.845V25.4719H264.819V34.8249H272.997V36.7989Z" fill="white" />
    <path d="M228.312 56.1159L238.276 41.9219L228.876 28.3859V25.9419H248.898V27.9629H231.085L240.72 41.8749L230.615 56.4449H250.449V58.5599H228.312V56.1159Z" fill="white" />
    <path d="M203.164 25.9419H211.53C213.504 25.9419 215.133 26.1142 216.418 26.4589C217.703 26.7722 218.737 27.2736 219.52 27.9629C220.303 28.6209 220.852 29.4826 221.165 30.5479C221.478 31.6132 221.635 32.8822 221.635 34.3549C221.635 35.2009 221.541 36.0156 221.353 36.7989C221.196 37.5822 220.914 38.3029 220.507 38.9609C220.131 39.6189 219.614 40.1829 218.956 40.6529C218.329 41.1229 217.546 41.4676 216.606 41.6869V41.8749C218.862 42.3136 220.444 43.1909 221.353 44.5069C222.293 45.7916 222.763 47.4836 222.763 49.5829C222.763 51.0556 222.575 52.3559 222.199 53.4839C221.854 54.6119 221.243 55.5519 220.366 56.3039C219.489 57.0559 218.329 57.6199 216.888 57.9959C215.478 58.3719 213.708 58.5599 211.577 58.5599H203.164V25.9419ZM210.355 40.8879C212.141 40.8879 213.614 40.7939 214.773 40.6059C215.964 40.3866 216.888 40.0262 217.546 39.5249C218.235 39.0236 218.721 38.3499 219.003 37.5039C219.285 36.6579 219.426 35.6082 219.426 34.3549C219.426 33.0076 219.254 31.9109 218.909 31.0649C218.596 30.2189 218.063 29.5609 217.311 29.0909C216.559 28.6209 215.588 28.3076 214.397 28.1509C213.206 27.9942 211.749 27.9159 210.026 27.9159H205.373V40.8879H210.355ZM211.06 56.5389C212.94 56.5389 214.491 56.4136 215.713 56.1629C216.966 55.8809 217.938 55.4579 218.627 54.8939C219.348 54.3299 219.849 53.6092 220.131 52.7319C220.413 51.8546 220.554 50.8049 220.554 49.5829C220.554 48.4236 220.397 47.4209 220.084 46.5749C219.771 45.7289 219.222 45.0396 218.439 44.5069C217.656 43.9429 216.622 43.5356 215.337 43.2849C214.052 43.0029 212.439 42.8619 210.496 42.8619H205.373V56.5389H211.06Z" fill="white" />
</svg>

const HomePage = () => {
    const squareRef = useRef(null); // 创建一个ref用于引用正方形DOM元素

    const [language] = useState("zh")
    const [showLanMenu, setShowLanMenu] = useState(false)
    const [isActive, setIsActive] = useState(false);

    const [data, setData] = useState(homeData['zh'])

    const [downloadIDAModel,setDownloadIDAModel] = useState(false)
    

    const { i18n } = useTranslation()
    const [projectLanguageLast, setProjectLanguage] = useState('zh')
    const [showLogin, setShowLogin] = useState(false)
    const loginGuard = useGuard();
    useEffect(() => {
        /**
         * 缓存语言
         */

        let default_language = localStore.get('projectLanguageLast') || projectLanguageLast
        if (!projectLanguageLast) {
        /**
         * 浏览器语言默认语言 
         */
        const browserLang = getBrowserLang()

        if (browserLang) {
            default_language = browserLang
        }
        // 设置localstorage中的浏览器语言
        localStore.set('browserLanguageLast', default_language)
        // 设置localstorage中的项目语言
        localStore.set('projectLanguageLast', default_language)
        }

        i18n.changeLanguage(default_language)
        localStore.set('projectLanguageLast', default_language)
        setProjectLanguage(default_language)
        setData(homeData[default_language])

        reportLog.logHome()

        loginGuard.visible = false
        loginGuard.changeLang(default_language == 'zh' ? 'zh-CN' : 'en-US')
        loginGuard.on('login', user => {
            window.location.href = '/analysis/'
        })
    }, [])



    const showDownloadIDA = () => {
        window.location.href = 'https://ammhnavwh6.feishu.cn/share/base/form/shrcnv4gWOwA8DZY2FGmi9pS2Gg'
        //setDownloadIDAModel(true)
    }

    const tryOnline = () =>{
        loginGuard.trackSession().then((user) => {
            if (user != null) {
                window.location.href = '/analysis/'
            } else {
                loginGuard.show()
            }});
    }

    const CancelDownloadIDA = ()=>{
        setDownloadIDAModel(false)
    }
    const projectLanguageChange = (language) => {
        // setLanguage(language)
        setData(homeData[language])
        let _language = language
        i18n.changeLanguage(_language)
        localStore.set('projectLanguageLast', _language)
        setProjectLanguage(_language)
        loginGuard.changeLang(language == 'zh' ? 'zh-CN' : 'en-US')
    }
    const toggleSquare = (event) => {
        event.stopPropagation(); // 阻止事件冒泡到容器
        setShowLanMenu(!showLanMenu);
        setIsActive(!isActive);
    };

    // 容器点击事件处理函数
    const handleContainerClick = (event) => {
        // 如果点击的是正方形以外的区域，隐藏正方形
        if (squareRef.current && !squareRef.current.contains(event.target)) {
            setShowLanMenu(false);
        }
    };
    const projectLanguageChangeMobile = (language) => {
        // setLanguage(language)
        setData(homeData[language])
        let _language = language
        i18n.changeLanguage(_language)
        localStore.set('projectLanguageLast', _language)
        setProjectLanguage(_language)
        setShowLanMenu(false)
        setIsActive(!isActive);
        loginGuard.changeLang(language == 'zh' ? 'zh-CN' : 'en-US')
    }
    return (
        <div className={`home-page-container ${projectLanguageLast === 'en' ? "home-page-container-en" : ""} ${isUserMobile()?"home-page-container-mobile":""}`}>
            <div className="home-page-header">
                <LogoSvg></LogoSvg>
                <div className="language-menu">
                    <div className="language-zh" onClick={() => projectLanguageChange('zh')}>中文<div className='language-active-line' style={{ opacity: projectLanguageLast === "zh" ? 1 : 0 }}></div></div>
                    <div className="language-en" onClick={() => projectLanguageChange('en')}>EN<div className='language-active-line' style={{ opacity: projectLanguageLast === "en" ? 1 : 0 }}></div></div>
                </div>
                {isUserMobile() ? <div className="language-menu-mobile" onClick={handleContainerClick}>
                    <div onClick={toggleSquare} className={`toggle-button menu-toggle ${isActive ? 'active' : ''}`}>
                        <span className="menu-toggle-line"></span>
                        <span className="menu-toggle-line"></span>
                        <span className="menu-toggle-line"></span>
                    </div>
                    <div
                        ref={squareRef} // 将ref附加到正方形上
                        className={`menu-list`}
                        style={{ top: showLanMenu ? "0px" : "-158px" }}
                    >
                        <div className="language-zh-mobile" onClick={() => projectLanguageChangeMobile('zh')}>中文<div className='language-active-line' style={{ opacity: projectLanguageLast === "zh" ? 1 : 0 }}></div></div>
                        <div className="language-en-mobile" onClick={() => projectLanguageChangeMobile('en')}>EN<div className='language-active-line' style={{ opacity: projectLanguageLast === "en" ? 1 : 0 }}></div></div>
                    </div>
                </div>
                    : ""
                }
            </div>
            <div className="home-content home-content-green home-content-0-row">
                <div className="home-content-left">
                    <div className="main-title">{data[0].title.split(" ").map(el=><span key={el}>{el}</span>)}</div>
                    <div className="main-title-logo-container">
                        <div className="mlm-logo" style={{    marginLeft: "6px",marginTop: "16px"}}>
                        <MLMSvg />
                        </div>
                        {/* <div className="main-title-logo">M</div>
                        <div className="main-title-logo main-title-logo-green">L</div>
                        <div className="main-title-logo">M</div>
                        <div className="main-title-beta">BΣ†Δ</div> */}

                    </div>
                </div>
                <div className="home-content-right">
                    <div className="">{data[0].content}</div>
                    <div className="button-box">
                        <div className="download-button" onClick={showDownloadIDA}>{data[0].button2}</div>
                        <div className="download-button" onClick={tryOnline}>{data[0].button1} <TruBottonSvg /></div>
                        <div className="download-button" onClick={() => { window.open('https://mlm.lingyiwanwu.com/download/MLM%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.pdf')}}>{data[0].button3}</div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="home-content home-content-1-row">
                <div className="home-content-left home-content-left-1">
                    <div className="">{data[1].no}</div>
                    <div className="home-content-left-1-green">{data[1].one}</div>
                    {/* <div className="home-content-left-1-title">{data[1].title}</div> */}
                </div>
                <div className="home-content-right">
                    <div className="home-content-right-des-re-flex" style={{ flexDirection: language === "zh" ? 'column' : 'column-reverse' }}>
                        <div className="home-content-right-des-line1">{data[1].des1}</div>
                        <div className="home-content-right-des-line2">{data[1].des2}</div>
                    </div>
                    <div className="home-content-right-des-line3">{data[1].des3}</div>
                </div>
            </div>
            {/*  */}
            <div className="home-content home-content-2-row">
                <div className="home-content-left home-content-left-sanjiao">
                    <SanjiaoSvg /><div className="home-content-left-title">{data[2].title}</div>
                </div>
                <div className="home-content-right">
                    <div className="home-content-right-title-2">
                        <div style={{ marginRight: "16px", display: "inline-block" }}><BingoSvg /></div>
                        {data[2].des1}
                    </div>
                    <div className="home-content-right-des-2">{data[2].des2}</div>
                    <div className="home-content-right-title-2">
                        <div style={{ marginRight: "16px", display: "inline-block" }}><BingoSvg /></div>
                        {data[2].des3}</div>
                    <div className="home-content-right-des-2">{data[2].des4}</div>
                    <div className="home-content-right-title-2">
                        <div style={{ marginRight: "16px", display: "inline-block" }}><BingoSvg /></div>
                        {data[2].des5}
                    </div>
                    <div className="home-content-right-des-2">{data[2].des6}</div>
                </div>
            </div>
            <div className="home-content home-content-3-row">
                <div className="home-content-left home-content-left-sanjiao">
                    <SanjiaoSvg /><div className="home-content-left-title">{data[3].title}</div>
                </div>
                <div className="home-content-right">
                    <div className="home-content-right-title-3">
                        <div style={{ color: "#00FF25", display: "inline-block", marginRight: "15px" }}>{data[3].step1}</div>
                        {data[3].des1}
                        <div className="home-content-right-title-3-jiantou1">
                            <JiantouSvg />
                        </div>
                    </div>
                    <div className="home-content-right-des-3">{data[3].des2}</div>
                    <div className="home-content-right-title-3">
                        <div style={{ color: "#00FF25", display: "inline-block", marginRight: "15px" }}>{data[3].step2}</div>
                        {data[3].des3}

                        <div className="home-content-right-title-3-jiantou2">
                            <JiantouSvg />
                        </div>
                    </div>
                    <div className="home-content-right-des-3">{data[3].des4}</div>
                    <div className="home-content-right-title-3">
                        <div style={{ color: "#00FF25", display: "inline-block", marginRight: "15px" }}>{data[3].step3}</div>
                        {data[3].des5}</div>
                    {/* <div className="home-content-right-des-3-bottom">{data[3].des6}</div> */}
                    {/* <div className="home-content-right-des-3-bottom-2">{data[3].des7}</div> */}
                    {/*  */}


                </div>
            </div>
            <div className="home-content home-content-gray home-content-4-row">
                <div className="home-content-left home-content-left-sanjiao">
                    <SanjiaoSvg /><div className="home-content-left-title">{data[4].title}</div>
                </div>
                <div className="home-content-right">
                    <div className="home-content-right-title-4">{data[4].des1}</div>
                    <div className="home-content-right-title-4">{data[4].des2}</div>
                    <div className="home-content-right-title-4">{data[4].des3}</div>
                </div>
            </div>
            {/*  */}
            <div className="home-content home-content-green home-content-5-row">
                <div className="home-content-left">
                    <VULLogoSvg />
                </div>
                <div className="home-content-right">
                    <div className="home-content-right-des-5">{data[5].des1}</div>
                    <div className="home-content-right-des-5-bottom">{data[5].des2}</div>
                </div>
            </div>
            <Modal
                    title={projectLanguageLast === "en" ? "Instructions for use":"使用说明"}
                    width="446px"
                    open={downloadIDAModel}
                    onCancel={CancelDownloadIDA}
                    footer={[
                        <Button type='primary' key="download" onClick={CancelDownloadIDA}> 
                            <a href={`https://mlm.lingyiwanwu.com/download/mlm.zip`}> {projectLanguageLast === "en" ? "Download":"下载"} </a>
                        </Button>]
                        }
                    centered
                >
                {projectLanguageLast !== "en" ? <>
                    <div style={{ wordWrap: "no", fontSize: "16px", fontStyle: "normal", fontWeight: 400, lineHeight: "150%" }}>
                        <div style={{ display: "inline-block", color: "#00FF25" }}>Step1.</div>下载IDA插件并将插件放在IDA安装路径的plugins目录下。
                        <br />
                        <br />
                        <div style={{ display: "inline-block", color: "#00FF25" }}>Step2.</div>联系我们获取内测账号及token（<div style={{ color: "#00FF25", display: "inline-block", textDecorationLine: "underline" }}>mlm@vul337.team</div>  / <div style={{ color: "#00FF25", display: "inline-block", textDecorationLine: "underline" }}>mlm@01.ai</div>）。
                    </div>
                </> : <>

                    <div style={{ wordWrap: "no", fontSize: "16px", fontStyle: "normal", fontWeight: 400, lineHeight: "150%" }}>
                        <div style={{ display: "inline-block", color: "#00FF25" }}>Step1.</div>Download IDA plugin and place the plugin in the plugins directory of IDA installation path.
                        <br />
                        <br />
                        <div style={{ display: "inline-block", color: "#00FF25" }}>Step2.</div>Contact us to get the internal test account and token(<div style={{ color: "#00FF25", display: "inline-block", textDecorationLine: "underline" }}>mlm@vul337.team</div>  / <div style={{ color: "#00FF25", display: "inline-block", textDecorationLine: "underline" }}>mlm@01.ai</div>)。
                    </div>
                </>}
                    
                </Modal>
            <div>
                {<div id="authing-guard-container"></div>}
            </div>
        </div>
    )
}

export default HomePage;