import config from "../config";
import userInfo from "./userinfo";
import MlmError from "./mlmerror";
import {fetchEventSource} from '@microsoft/fetch-event-source';
export const EventStreamContentType = 'text/event-stream';
class DasmClient {
    constructor(file) {
        this.file = file
        this.mainFuncNames = ['main', '_main', 'start', '_start']
    }

    fileList() {
        return this.getJson(config.api.fileList)
    }

    all_functions() {
        return this.getJson(config.api.function, this.file)
    }

    search(query) {
        return this.getJsonWithParams(query = {
            query:query
        },config.api.search, this.file)
    }

    disasm(address) {
        return this.getJson(config.api.disasm, this.file, address)
    }

    decompile(address) {
        return this.getJson(config.api.decompile, this.file, address)
    }

    decompile_stream(address, {onopen, onmessage, onclose, onerror, signal}) {
        let url = config.baseUrl + '/' + config.api.decompile_stream + '/' + this.file + '/' + address
        return this.getJsonStream(url, {onopen, onmessage, onclose, onerror, signal})
    }
    explain(address) {
        return this.getJson(config.api.explain, this.file, address)
    }

    explain_stream(address, {onopen, onmessage, onclose, onerror, signal}) {
        let url = config.baseUrl + '/' + config.api.explain_stream + '/' + this.file + '/' + address
        return this.getJsonStream(url, {onopen, onmessage, onclose, onerror, signal})
    }

    rename(address) {
        return this.getJson(config.api.rename, this.file, address)
    }

    getToken() {
        return this.getJson(config.api.getToken)
    }

    updateRename(address, name){
        const data = {func_name:name}
        return this.postJson(data, config.api.updateRename, this.file, address)
    }

    userComment(address, comment){
        return this.postJson(comment, config.api.userComment, this.file, address)
    }

    closeAll() {
        return this.getJson(config.api.close_all)
    }

    getJsonWithParams(query, ...urls) {
        let url = config.baseUrl
        urls.forEach((item) => {
            url = url + '/' + item
        })
        if (query != null) {
            const params = new URLSearchParams();
            for (let key in query) {
                params.append(key, query[key])
            }
            url = url + '?' + params.toString()
        }
        return new Promise((resolve, reject) => {
            fetch(url,
                {
                    headers: {Authorization: userInfo.idToken}
                })
                .then(res => res.json())
                .then((result) => {
                        if (result.status == 'ok')
                            resolve(result.data)
                        else {
                            console.log('http get ' + url + 'result:' + result)
                            reject(new MlmError(result.status, result.error))
                        }
                    },
                    (error) => {
                        console.log('http get ' + url + 'result:' + error)
                        reject(error)
                    })
        })
    }
    getJson(...urls){
        return this.getJsonWithParams(null, ...urls)
    }

    postJson(data, ...urls){
        let url = config.baseUrl
        urls.forEach((item) => {
            url = url + '/' + item
        })
        return new Promise((resolve, reject) => {
            fetch(url,
                {
                    method: 'POST', // 指定请求方法为POST
                    headers: {
                        Authorization: userInfo.idToken,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(res => res.json())
                .then((result) => {
                        if (result.status == 'ok')
                            resolve(result.data)
                        else {
                            reject(new MlmError(result.status, result.error))
                        }
                    },
                    (error) => {
                        reject(error)
                    })
        })
    }

    getJsonStream(url, {onopen, onmessage, onclose, onerror, signal}) {
        return new Promise((resolve, reject) => {
            fetchEventSource(url,
                {
                    headers: {Authorization: userInfo.idToken},
                    openWhenHidden: true,
                    signal: signal,
                    async onopen(response) {
                        const contentType = response.headers.get('content-type');
                        if (!contentType?.startsWith(EventStreamContentType)) {
                            // 处理非流式数据
                            return new Promise((resolve, reject) => {
                                response.json().then((result) => {
                                    if (result.status == 'ok')
                                        onopen && onopen({type:'json', data:result.data})
                                    else
                                        onerror && onerror(new MlmError(result.status, result.error))
                                    reject(new Error('close'))
                                }).catch((err) => {
                                    onerror && onerror(err)
                                    reject(new Error('close'))
                                })
                            })
                        }else
                            onopen && onopen({type:'stream'})
                    },
                    onmessage(ev) {
                        onmessage(JSON.parse(ev.data))
                    },
                    onclose() {
                        onclose && onclose()
                    },
                    onerror(err) {
                        if (err.message && err.message == 'close')
                            onclose && onclose()
                        else
                            onerror && onerror(err)
                        throw err
                    }
                })
                .then((result) => {
                    console.log(result)
                    resolve()
                })
                .catch((err) => {
                    if ( err.message != 'close')
                        reject(err)
                })
        })
    }

    findMainFunction(functions) {
        /**
         * 查找入口函数，优先级 [main, _main, start, _start]
         * @type {null}
         */
        // 这四个找到，
        function find(s) {
            let result = null
            functions.some((item) => {
                if (item.name == s) {
                    result = item
                    return true
                }
            })
            return result
        }

        let mainItem = null

        this.mainFuncNames.some(name => {
            let result = find(name)
            if (result) {
                mainItem = result
                return true
            }
        })
        
        if (mainItem == null)
            mainItem = functions[0]
        return mainItem
    }
    isMainFunction(s){
        return this.mainFuncNames.indexOf(s) >= 0
    }
}

export default DasmClient