let config = {
   baseUrl: ""
}

config.funName2Addr = {}

config.api = {
    function :   "api/function",
    disasm :     "api/disasm",
    decompile :  "api/decompile",
    decompile_stream :  "api/decompile_stream",
    explain_stream:     "api/explain_stream",
    explain:     "api/explain",
    rename:      "api/rename",
    updateRename:"api/updatename",
    fileUpload : "api/file/upload",
    fileList :   "api/file/list",
    close_all:   "api/close_all",
    search:      "api/search",
    getToken:      "api/get_token",
    userComment:  "api/feedback/user_comment"
}

export default config
