import EventEmitter from 'events';

// 全局事件监听
class Browser extends EventEmitter {
    msgOpenFileDialog   = 'msgOpenFileDialog'
    msgOpenFile         = 'msgOpenFile'
    msgRenameFunction   = 'msgRenameFunction'   //函数改名消息
    msgOpenFunction     = 'msgOpenFunction'     //打开函数消息
    msgActiveFunction   = 'msgActiveFunction'    //函数被激活
    msgCCodeDone   = 'msgCCodeDone'    //伪代码加载完成
    // 
    msgPageLayout = 'msgPageLayout'
    msgCodeFunEvent = 'msgCodeFunEvent'
    msgCodeFunLoadingEvent = 'msgCodeFunLoadingEvent'
    // 
    constructor() {
        super()
        this.file = null ;// 当前打开的文件
        this.setMaxListeners(10)
    }

    onEvent(eventName, listener) {
        this.on(eventName, listener)
        return {
            eventName: eventName,
            listener: listener
        }
    }
    emitOpenFileDialog(...args) {
        // 实现打开文件对话框
        this.emit(this.msgOpenFileDialog, args)
    }

    onOpenFileDialog(listener) {
        return this.onEvent(this.msgOpenFileDialog, listener)
    }
    offOpenFileDialog(listener) {
        this.off(this.msgOpenFileDialog, listener)
    }

    emitOpenFile(uuid){
        // 打开文件消息
        this.emit(this.msgOpenFile, uuid)
    }
    onOpenFile(listener){
        return this.onEvent(this.msgOpenFile, listener)
    }
    offOpenFile(listener){
        this.off(this.msgOpenFile, listener)
    }

    emitOpenFunction(item){
        this.emit(this.msgOpenFunction, item)
    }

    onOpenFunction(listener){
        return this.onEvent(this.msgOpenFunction, listener)
    }
    offOpenFunction(listener){
        this.off(this.msgOpenFunction, listener)
    }

    emitRenameFunction(item){
        this.emit(this.msgRenameFunction, item)
    }

    onRenameFunction(listener){
        return this.onEvent(this.msgRenameFunction, listener)
    }
    offRenameFunction(listener){
        this.off(this.msgRenameFunction, listener)
    }

    // msgActiveFunction
    emitActiveFunction(addr){
        // 通知
        this.emit(this.msgActiveFunction, addr)
    }
    onActiveFunction(listener){
        return this.onEvent(this.msgActiveFunction, listener)
    }
    offActiveFunction(listener){
        this.off(this.msgActiveFunction, listener)
    }
    // 
    emitCCodeDone(addr){
        this.emit(this.msgCCodeDone, addr)
    }

    onCCodeDone(listener){
        return this.onEvent(this.msgCCodeDone, listener)
    }
    offCCodeDone(listener){
        this.off(this.msgCCodeDone, listener)
    }
    // 
    closeEvents(eventList){
        eventList.map((events) =>{
            this.off(events.eventName, events.listener)
            console.log('Close events ' + events.eventName)
        })
    }

    // 页面配置
    // msgPageLayout
    emitPageLayout(...args) {
        this.emit(this.msgPageLayout, args)
    }
    onPageLayout(listener) {
        return this.onEvent(this.msgPageLayout, listener)
    }
    offPageLayout(listener) {
        this.off(this.msgPageLayout, listener)
    }
    // 函数功能
    // msgCodeFunEvent
    emitCodeFunEvent(...args) {
        this.emit(this.msgCodeFunEvent, args)
    }
    onCodeFunEvent(listener) {
        return this.onEvent(this.msgCodeFunEvent, listener)
    }
    offCodeFunEvent(listener) {
        this.off(this.msgCodeFunEvent, listener)
    }
    // active 状态
    // msgCodeFunEvent
    emitCodeFunLoadingEvent(...args) {
        this.emit(this.msgCodeFunLoadingEvent, args)
    }
    onCodeFunLoadingEvent(listener) {
        return this.onEvent(this.msgCodeFunLoadingEvent, listener)
    }
    offCodeFunLoadingEvent(listener) {
        this.off(this.msgCodeFunLoadingEvent, listener)
    }
}

let browser = new Browser()
export default browser