import React from 'react';

import {List, message, Input, Button, Space, Modal} from 'antd';
import config from "../config";
import DasmClient from "../logic/dasmclient"
import {CloseOutlined} from '@ant-design/icons';
import browser from "./browser";
import {showErrorMessage} from "../util/errormessage";
import reportLog from "../logic/reportLog";

const {Search} = Input;

class ExplorerView extends React.Component {
    constructor(props) {
        super(props)
        this.dasmClient = new DasmClient(browser.file)
        this.state = {
            status: null,
            items: [],
            onSearch: false,
            searchValue: "",
            projectLanguage: props.language,
            searchIng:false
        };
        this.functionNames = []
        this.activeKey = null
        this.loadData()

        this.eventList = []
        // 处理函数改名消息，根据address遍历函数列表，修改函数名
        this.eventList.push(browser.onRenameFunction(
            (funItem) => {
                try {
                    const address = Number(funItem.address)
                    const {items} = this.state
                    items.forEach((item) => {
                        if (item.address == address) {
                            console.log("rename ok " + funItem.address + ' ' + funItem.name)
                            item.name = funItem.name
                        }
                    })
                    this.setState(items)
                    if (this.state.onSearch){
                        this.functionNames.forEach((item) => {
                            if (item.address == address) {
                                item.name = funItem.name
                            }
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
            })
        )
        browser.onActiveFunction((activeKey)=>{
                this.activeFunction(activeKey)
            })
        browser.onOpenFile((uuid)=>{
            this.dasmClient = new DasmClient(browser.file)
            this.loadData()
        })
    }

    // 选中某条函数，高亮显示.
    activeFunction(activeKey){
        function inView(el){
            let y = el.offsetTop + 40 // List header 大小，暂时硬编码
            let funcList = document.getElementById('func_List')
            if ( funcList != null ) {
                let top = funcList.scrollTop;
                let bottom = top + funcList.getBoundingClientRect().height
                return top <= y && y < bottom
            }
            return true
        }
        function fontBold(key, bold){
            const els = document.getElementsByClassName('func' + key);
            Array.prototype.forEach.call(els, function(element) {
                // element.style.fontWeight = bold ? 'bold' :'normal'; // 将文本颜色改为红色
                if (bold) {
                    // 选中
                    element.style.background = "#323e44"
                    element.style.color = "#488CDC"
                    element.getElementsByClassName('hex-color')[0].style.color = "#fff"
                    element.style.opacity=1
                }else{
                    // 选中后
                    element.style.removeProperty('background')
                    element.style.setProperty('color', '#3A6AA1')
                    element.getElementsByClassName('hex-color')[0].style.color = "#5D6D7A"
                    // element.style.background = "transparent"
                    // element.style.color = "#869cb0"    // 恢复颜色
                }

                if ( bold && !inView(element)){ // 滚动卷滚条，使其可见
                    element.scrollIntoView({behavior: 'smooth', inline:'start'})
                }
            });
        }
        if (this.activeKey != null){
            fontBold(this.activeKey, false)
        }
        if ( activeKey != null) {
            this.activeKey = activeKey
            fontBold(this.activeKey, true)
        }
    }

    loadData() {
        if (this.dasmClient.file == null)
            return
        this.setState({status: 'doing'})
        this.dasmClient.all_functions()
            .then((data) => {
                this.setState({
                    status: 'done',
                    onSearch: false,
                    searchIng: false,
                    searchValue: "",
                    items: data.functions
                });
                this.functionNames = data.functions
                if (data.functions.length > 0) {
                    // 查找main函数[main, _main, start, _start]
                    this.handleItemClick(this.dasmClient.findMainFunction(data.functions))
                }
                // 构建函数地址索引表
                config.funName2Addr = {}
                data.functions.forEach((item) => config.funName2Addr[item.name.trim()] = item)

                reportLog.logFunctions(this.dasmClient.file, data.tokens)
            })
            .catch((error) => {
                this.setState({
                    status: 'done'
                });
                showErrorMessage(error)
            })
    }

    componentDidUpdate() {
        if ( this.activeKey != null)
            this.activeFunction(this.activeKey)
    }

    // 函数被点击，发起打开函数事件
    handleItemClick = (item) => {
        browser.emitOpenFunction(item)
        this.activeFunction(item.address) //高亮函数名
    }

    searchOnFunctionName(key){
        const result = []
        this.functionNames.forEach((item) => {
            if (item.name.startsWith(key)) {
                result.push(item)
            }
        })
        return result
    }
    handleOnSearch = (text, event, info) => {
        this.setState({ searchIng: true })
        const result = this.searchOnFunctionName(text)
        this.setState({
            items: result,
        })
        this.dasmClient.search(text)
            .then((data) => {
                this.activeFunction(null)
                this.setState({
                    onSearch: true,
                    items: result.concat(data.candidate_ea),
                    searchIng: false
                })
                reportLog.logSearch(this.dasmClient.file, text, data.tokens)
            })
            .catch((error) => {
                showErrorMessage(error)
            })
    }
    handleCloseSearch = () => {
        this.activeFunction(null)
        this.setState({
            onSearch: false,
            items: this.functionNames,
            searchValue: ""
        })
    }
    handleSearchValue = (e) => {
        this.setState({
            searchValue: e.target.value
        })
    }
    render() {
        const { items, status, searchIng, onSearch,searchValue } = this.state
        const FunctionList = this.state.projectLanguage==="en"?"Function List":"函数列表"
        const OpeningFile = this.state.projectLanguage==="en"?"Please wait while the file is opened":"正在打开文件，请稍候"


        const loadingTip = (<Modal open={status == 'doing'} maskClosable={false} centered={true}
                                   footer={[]} closeIcon={false}>
                <div align={'center'}>{OpeningFile}</div>
                </Modal>
        )
        return (
            <div className='bin2code-left-list' style={{
                left: 0,
                top: 0,
                bottom: 0,
                overflow: "hidden"
                // background: '#ffffff'
            }}>
                <Space.Compact style={{width: 'calc(100% - 24px)',padding:"0 16px"}}>
                    <Search placeholder="Search functions with natural language. E.g.,crypto" value={searchValue} onChange={(e) => this.handleSearchValue(e)} size={'large'} loading={searchIng} onSearch={this.handleOnSearch} />
                    {onSearch && <Button size={'large'} onClick={this.handleCloseSearch}>
                        <CloseOutlined />
                    </Button>
                    }

                </Space.Compact>
                <div style={{
                    height: 'calc(100vh - 136px)',
                    overflow: 'auto',
                    padding:"0 8px",
                    marginTop:"8px"
                }} id={'func_List'}>
                    <List
                        header={<div  style={{
                            paddingLeft: '8px',
                            color: "#e3e7ee",
                            textWrap: "nowrap"
                        }}>{FunctionList}</div>}
                        // bordered
                        size="small"
                        dataSource={items}
                        renderItem={item => (
                            <List.Item onClick={() => this.handleItemClick(item)}
                                style={{
                                    color: "#488CDC",
                                    paddingRight: "8px",
                                    paddingLeft: '8px', overflow: "hidden"
                                }}
                                className={'nowrap func' + item.address}>
                                  <span className='hex-color' style={{
                                      fontFamily: "monospace",
                                      color: "#869cb0",
                                      letterSpacing:"-0.5px"
                                  }}>
                                    0x{item.address.toString(16).padStart(8, '0')}:
                                </span> {item.name}

                            </List.Item>
                        )}
                    />
                </div>
                {loadingTip}
            </div>
        )
    }
}

export default ExplorerView;