const zh = [
    {
        title: "机器语言大模型",
        content: "MLM是清华大学网络研究院VUL337团队联合零一万物、华清未央开发的机器语言大模型，首次全面支持可执行程序/二进制程序/闭源软件结构分析、语义理解、安全分析，不依赖源代码或调试信息，解决二进制代码信息缺失和语义理解困难等难题，突破软件安全分析技术面临的瓶颈，为漏洞、恶意代码、供应链、版权保护等软件安全问题提供高效智能化解决方案。",
        button1: "在线体验",
        button2: "申请试用",
        button3: "使用说明",
    },
    {
        no: "全球",
        one: "首发",
        // title: "全球第一款",
        des1: "基于大模型的",
        des2: "智能化软件逆向平台",
        des3: "颠覆软件逆向分析的工作模式，为用户提供智能、高效的交互式逆向分析体验。",
    },
    {
        title: "核心能力",
        des1: "语义分析",
        des2: "语义摘要生成、函数命名、函数功能分类",
        des3: "结构分析",
        des4: "函数边界识别、函数调用关系识别",
        des5: "安全分析",
        des6: "软件供应链安全、恶意代码分析与检测、漏洞挖掘与分析",
    },
    {
        title: "使用步骤",
        step1: "步骤1",
        step2: "步骤2",
        step3: "步骤3",
        des1: "上传二进制可执行文件",
        des2: "目前我们已支持10MB以内的x86/x64/ARM32/AArch64/PPC32/PPC64 PE/MACHO/ELF文件",
        des3: "选定指定函数生成反汇编片段 ",
        des4: "",
        des5: "智能生成C语言并获得语义解释",
        des6: "说明:采用IDA插件时，请在下载安装完成后联系我们获取token",
        des7: "联系方式：mlm@vul337.team / mlm@01.ai",
    },
    {
        title: "服务声明",
        des1: "作为中立的技术服务提供方，我们仅基于您上传的二进制文件进行分析。您需要确保上传的文件及使用本服务分析的全部内容合法、合规且已获得合法且充分的授权、保证有权且合规地使用本服务。",
        des2: "我们无法保证基于您提交的请求而向您反馈的相应分析内容的真实性、准确性和适用性，您需要自行掌握和判断。",
        des3: `由于我们的服务尚处于“邀测期”，“邀测账号”仅限于您个人使用，不允许转借、售卖账号等。您在使用本服务过程中不得从事违反法律法规、政策规范的行为，不得攻击或者滥用本服务，不得将分析内容用于买卖、交换、宣传等用途。`,
    },
    {
        // title: "logo",
        des1: "VUL337团队所在实验室隶属于清华大学网络研究院，承担了多项国家重要课题，在国际四大安全会议发表大量论文，孕育了“蓝莲花”“紫荆花”等国内外知名网络安全战队。",
        des2: "> 联系我们 mlm@vul337.team  /  mlm@01.ai"
    },
]

const en = [
    {
        title: "Machine Language Model",
        content: "MLM is a large language model developed by VUL337 team of Institute for Network Sciences and Cyberspace, Tsinghua University in conjunction with 01.AI and AscendGrace Tech. It marks the first comprehensive support for the analysis of executable programs, binary code, and closed-source software structures, as well as semantic understanding and security analysis. MLM operates independently of source code or debugging information, addressing challenges such as the lack of information in binary code and difficulties in semantic understanding. This breakthrough overcomes the bottlenecks faced by software security analysis technologies, providing efficient and intelligent solutions for issues related to vulnerabilities, malicious code, supply chain security, and copyright protection.",
        button1: "Try Now",
        button2: "Apply for trial",
        button3: "Instruction",
    },
    {
        no: "The World's",
        one: "First Ever",
        // title: "in the world",
        des1: "Based on LLM",
        des2: "Intelligent Software Reverse Platform",
        des3: "Subvert the working mode of software reverse analysis and provide users with intelligent and efficient interactive reverse analysis experience.",
    },
    {
        title: "Core Competencies",
        des1: "Semantic Analysis",
        des2: "Semantic summary generation, function naming, function categorization.",
        des3: "Structural Analysis",
        des4: "Function boundary identification, indirect call recognition.",
        des5: "Security Analysis",
        des6: "Software supply chain security, malicious code analysis and detection, vulnerability mining and analysis.",
    },
    {
        title: "Usage Steps",
        step1: "Step 1",
        step2: "Step 2",
        step3: "Step 3",
        des1: "Upload a binary executable file",
        des2: "Current support x86/x64/ARM32/AArch64/PPC32/PPC64 PE/MACHO/ELF files up to 10MB",
        des3: "Generate disassembly for selected functions. ",
        des4: " ",
        des5: "Intelligently generate C-like code and get semantic interpretation.",
        des6: "TokenNote: When using IDA plugin, please contact us to get the token after downloading and installing. ",
        des7: "> Contact Us: mlm@vul337.team / mlm@01.ai",
    },
    {
        title: "Service Disclaimer",
        des1: "As a neutral technical service provider, we only analyze binary files based on your uploads. You are required to ensure that the uploaded files and all content analyzed using the Service are legal, compliant and legally and fully authorized, and that you have the right to use the Service in a compliant manner.",
        des2: "We cannot guarantee the truthfulness, accuracy and suitability of the content analyzed based on your request and you are responsible for making your own judgment.",
        des3: `As our Service is still in the "Invitation to Test" period, the "Invitation to Test Account" is for your personal use only, and you are not allowed to lend or sell the account. You are not allowed to engage in behavior that violates laws, regulations, policies and norms in the process of using the service, attack or abuse the service, or use the analytical content for trading, exchanging, publicity and other purposes.`,
    },
    {
        // title: "logo",
        des1: "The VUL337 team’s lab is part of Institute for Network Sciences and Cyberspace, Tsinghua University,  The lab has undertaken a number of important national projects, published a large number of papers in the Top-4 international security conferences, and is the base of the CTF teams Blue-Lotus and RedBud.",
        des2: "> Contact Us: mlm@vul337.team  /  mlm@01.ai"
    },

]

let data = { zh, en }

export default data
