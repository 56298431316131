
const zh = {
    yi:{
        language:"zh",
        hello:"Hi,我是一灵",
        speak:"不只是聊聊天，",
        startButton:"开始聊天",
        // 
        sendChat:"发信息...",
        uploading:"文件上传中...",
        footerLegal:"注意：以上回复全部由AI技术合成，复制传播需要遵守相关国家法律法规及一灵用户条款，",
        Service:"《服务协议》",
        Privacy:"《隐私政策》",
        UseAgreement:"使用协议",
        AndText:"和",
        Privacy2:"隐私政策",
        Privacy3:"隐私政策。",
        // 
        ContactUs:"联系我们",
        UserAgreement:"用户协议",
        TermsOfService:"服务条款",
        logOut:"退出登录",
        // 
        enterTelphone:"请输入您的手机号",
        telphoneError:"手机号错误，请重新填写",
        nextStep:"下一步",
        youAgree:"登录即代表您同意 一灵 的",
        sixDigitVerifiCode:"输入 6 位验证码",
        hasSendCode:"验证码已发送至",
        loginFailed:"登录失败",
        loginError:"登录错误",
        reSend:"重新发送",
        UserAgreementModel:"使用协议及隐私保护",
        Agree:"同意",
        Disagree:"同意",
        //
        noUser:"未登录",
        accountNumber:"账号",
        // 
        interface:{
            error:"接口错误",
            waitingDialogue:"对话进行中,请稍等片刻",
            uploadingPic:"图片上传中",
            reGenerate:"请重新生成错误的对话",
            detectedUpdate:"检测到会话更新",
            frequentOperation:"Your request is too frequent. Please retry after one minute.",
            serverIsUnstable:"服务器不稳定，请稍后",
            verifiCodeFail:"获取验证码失败",
            verifiCodeError:"获取验证码错误",
            uploadFailed:"上传失败",
            uploadLinkFailed:"获取上传链接失败"
        },
        // 
        Like:"赞",
        UnLike:"踩",
        Refresh:"刷新",
        delHistory:"删除历史记录",
        Report:"举报",
        Submit:"提交",
        Confirm:"确认",
        Cancel:"取消",
        reportReason:"我们想知道你举报我们的原因，你可以描述你遇到的问题。",
        delConfirm:"删除所有历史数据，删除后将不可恢复，您确认继续删除这些内容吗？",
        report:{
            Harmful:"有害/不安全",
            Porn:"色情低俗",
            Insulting:"谩骂攻击",
            Illegal:"违法犯罪",
            Other:"其他",
        },
        
        // 移动端
        mobile:{
            welcome:"欢迎体验",
            comingSoon:"目前移动端版本尚未推出，",
            PleaseUseComputer:"请使用电脑访问网页"
        },
    },
    page: {
        title: ' Chat模型体验',
        pageError: "出错了！",
        page404: "404 页面不存在",
        backHome: "回到首页",
    },
    header: {
        logout: "退出登录",
        confirmLogout: "确认退出登录",
        logoutInfo: "退出登录将终止您在网页中的登录状态。",
        cancle: "取消",
        notLogin: "未登录"
    },
}

export default zh