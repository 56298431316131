// React 16 / 17
// 代码示例：https://github.com/Authing/Guard/tree/v5/examples/guard-react/normal/src/pages/Embed.tsx
import { useGuard } from "@authing/guard-react18";
import React, { useEffect } from "react";

export default function CheckLogin({loginState, logout}) {
    // 获取 Guard 实例
    const guard = useGuard();
    useEffect(() => {
        if (logout){
            guard.logout().then(()=>{
                loginState(null, 'logout')
            })
        }else {
            guard.trackSession().then((user) => {
                loginState(user, 'login')
            })
        }
    }, []);

    return (
        <div>
            <div id="authing-guard-container"></div>
        </div>
    );
}
