import userInfo from "./userinfo";
class ReportLog {

    constructor() {
        this.appid = 6;
        this.aid = 32011;
    }

    logHome(){
        let data = {
            appid: this.appid,
            event :         'pagehome',
            client_type:    'web',
        }

        this.report(data)
    }

    logUploadFile(file, name, size){
        let data = {
            appid: this.appid,
            event :         'upload',
            client_type:    'web',
            user_id :       userInfo.userId,
            login_name:     userInfo.loginName,
            file_uuid:      file,
            file_name:      name,
            file_size:      size
        }

        this.report(data)
    }

    logFunctions(file, token){
        let data = {
            appid: this.appid,
            event :         'functions',
            client_type:    'web',
            user_id :       userInfo.userId,
            login_name:     userInfo.loginName,
            file_uuid:      file,
            input_token:    token.input,
            output_token:   token.output
        }

        this.report(data)
    }
    logDisasm(file, addr, token){
        let data = {
            appid: this.appid,
            event :         'diasm',
            client_type:    'web',
            user_id :       userInfo.userId,
            login_name:     userInfo.loginName,
            file_uuid:      file,
            address:        addr,
            input_token:    token.input,
            output_token:   token.output,
            repeat_count:   0
        }
        this.report(data)
    }

    logDecompile(file, addr, repeat, token){
        let data = {
            appid: this.appid,
            event :         'decompile',
            client_type:    'web',
            user_id :       userInfo.userId,
            login_name:     userInfo.loginName,
            file_uuid:      file,
            address:        addr,
            input_token:    token.input,
            output_token:   token.output,
            repeat_count:   repeat
        }
        this.report(data)
    }

    logExplain(file, addr, repeat, token){
        let data = {
            appid: this.appid,
            event :         'explain',
            client_type:    'web',
            user_id :       userInfo.userId,
            login_name:     userInfo.loginName,
            file_uuid:      file,
            address:        addr,
            input_token:    token.input,
            output_token:   token.output,
            repeat_count:   repeat
        }
        this.report(data)
    }

    logGenerateName(file, addr, token){
        let data = {
            appid: this.appid,
            event :         'generate_name',
            client_type:    'web',
            user_id :       userInfo.userId,
            login_name:     userInfo.loginName,
            file_uuid:      file,
            address:        addr,
            input_token:    token.input,
            output_token:   token.output,
            repeat_count:   0
        }
        this.report(data)
    }

    logUpdateName(file, addr, oldName, newName){
        let data = {
            appid: this.appid,
            event :         'update_name',
            client_type:    'web',
            user_id :       userInfo.userId,
            login_name:     userInfo.loginName,
            file_uuid:      file,
            address:        addr,
            old_name:       oldName,
            new_name:       newName,
        }
        this.report(data)
    }

    logSearch(file, query, token){
        let data = {
            appid: this.appid,
            event :         'search',
            client_type:    'web',
            user_id :       userInfo.userId,
            login_name:     userInfo.loginName,
            file_uuid:      file,
            query:          query,
            input_token:    token.input,
            output_token:   token.output,
        }
        this.report(data)
    }

    logDownPlugin(){
        let data = {
            appid: this.appid,
            event :         'down_plugin',
            client_type:    'web',
        }
        this.report(data)
    }

    report(data) {
        window.xaf && window.xaf.record({
            aid: this.aid,
            recordId: this.appid,
            recordData: data,
            complete: function (res) {
                console.log(data)
            },
            error: function (err){
                console.log(err)
            }
        })
    }
}

let reportLog = new ReportLog()

export default reportLog