
const en = {
    yi:{
        language:"en",
        hello:"Hi, I am Yi Ling",
        speak:"More than just a chat, ",
        startButton:"Let's Talk",
        // 
        sendChat:"发信息...",
        uploading:"文件上传中...",
        footerLegal:"注意：以上回复全部由AI技术合成，复制传播需要遵守相关国家法律法规及一灵用户条款，",
        Service:"《服务协议》",
        Privacy:"《隐私政策》",
        UseAgreement:"Use Agreement",
        AndText:"and",
        Privacy2:"Privacy Policy",
        Privacy3:"Privacy Policy.",
        // 
        ContactUs:"Contact Us",
        UserAgreement:"User Agreement",
        TermsOfService:"Terms of Service",
        logOut:"Log out",
        // 
        enterTelphone:"Your phone number",
        telphoneError:"手机号错误，请重新填写",
        nextStep:"Next",
        youAgree:"I agree to Yi Ling's ",
        sixDigitVerifiCode:"Enter Code",
        hasSendCode:"Verification code was just sent to ",
        loginFailed:"登录失败",
        loginError:"登录错误",
        reSend:"Don’t receive? Resend",
        UserAgreementModel:"使用协议及隐私保护",
        Agree:"同意",
        Disagree:"同意",
        //
        noUser:"未登录",
        accountNumber:"Account",
        // 
        interface:{
            error:"Interface error",
            waitingDialogue:"The conversation is in progress, please wait a moment",
            uploadingPic:"Image uploading",
            reGenerate:"Ops, please regenerate the conversation",
            detectedUpdate:"Detected session update",
            frequentOperation:"Your request is too frequent. Please retry after one minute.",
            serverIsUnstable:"Server is unstable, please try again later",
            verifiCodeFail:"Failed to retrieve verification code",
            verifiCodeError:"Error to retrieve verification code",
            uploadFailed:"Failed to upload image",
            uploadLinkFailed:"Failed to obtain upload link"
        },
        // 
        Like:"Like",
        UnLike:"Dislike",
        Refresh:"Regenerate",
        delHistory:"Clear history",
        Report:"Report",
        Submit:"Submit",
        Confirm:"Confirm",
        Cancel:"Cancel",
        reportReason:"We want to know why you reported us, and you can describe the problem you encountered.",
        delConfirm:"删除所有历史数据，删除后将不可恢复，您确认继续删除这些内容吗？",
        report:{
            Harmful:"Harmful/Unsafe",
            Porn:"Erotic vulgarity",
            Insulting:"Abuse and attack",
            Illegal:"Illegal crimes",
            Other:"Other",
        },
        
        // 移动端
        mobile:{
            welcome:"欢迎体验",
            comingSoon:"目前移动端版本尚未推出，",
            PleaseUseComputer:"请使用电脑访问网页"
        },
    },
    page: {
        title: ' Chat Model',
        pageError: "An error occurred!",
        page404: "404 Page not found",
        backHome: "Back to home page",
    },
    header: {
        logout: "Log out",
        confirmLogout: "Confirm logout",
        logoutInfo: "Logging out will terminate your current login status in your browser.",
        cancle: "Cancel",
        notLogin: "Sign in"
    },
}
export default en