import React from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, message, Upload, Typography,Table,Popover, Space } from 'antd';
import config from "../config";
import DasmClient from "../logic/dasmclient";
import userInfo from "../logic/userinfo";
import browser from "./browser";
import {showErrorMessage} from "../util/errormessage";
import reportLog from "../logic/reportLog";
const { Text} = Typography;

const LogoSvg = () => <svg width="71" height="24" viewBox="0 0 71 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.112 0.575998H23.632V24H19.216V8.896L13.264 17.76H10.832L4.848 8.832V24H0.464V0.575998H4.144L12.144 12.704L20.112 0.575998ZM66.7683 0.575998H70.2883V24H65.8723V8.896L59.9203 17.76H57.4883L51.5043 8.832V24H47.1203V0.575998H50.8003L58.8003 12.704L66.7683 0.575998Z" fill="white" />
    <path d="M28.5578 0.575998H33.1978V19.84H43.8538V24H28.5578V0.575998Z" fill="#00FF25" />
</svg>

class FileUploader  extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            items: [],
            projectLanguage:props.language
        };
        this.dasmClient = new DasmClient('')
    }

    componentDidMount() {
        this.reload()
    }

    reload = () => {
        this.dasmClient.fileList()
            .then((data) => {
                this.setState({
                    isLoaded: true,
                    items: data
                });
            })
            .catch((error) => {
                this.setState({
                    isLoaded: true
                });
                showErrorMessage(error)
            })
    }

    onItemClick = (uuid)=>{
        browser.file = uuid
        browser.emitOpenFile(uuid)
    }

    render() {
        const _self = this;
        const props = {
            name: 'file',
            action: config.baseUrl + '/' + config.api.fileUpload,
            headers: {
                Authorization: userInfo.idToken,
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    if ( info.file.response.status === 'ok') {
                        message.success(`${info.file.name} file uploaded successfully`);
                        _self.onItemClick(info.file.response.data.binary_uuid)

                        reportLog.logUploadFile(info.file.response.data.binary_uuid, info.file.name, info.file.size)
                    }
                    else
                        message.error(info.file.response.error.toString())
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload(file){
                const isLt2M = file.size / 1024 /1024 < 10;
                if (!isLt2M) {
                    message.error('文件大小限制10M!');
                }
                return isLt2M;
            }
        };
        const FileName = this.state.projectLanguage === "en" ? "File Name" : "文件名"
        const FileSize = this.state.projectLanguage === "en" ? "File Size" : "文件大小"
        const UploadTime = this.state.projectLanguage === "en" ? "Upload Time" : "上传时间"
        const Action = this.state.projectLanguage === "en" ? "Action" : "操作"
        const Download = this.state.projectLanguage === "en" ? "Download" : "下载"
        const Delete = this.state.projectLanguage === "en" ? "Delete" : "删除"

        const columns = [
            {
                title: FileName,
                dataIndex: 'file',
                key: 'file',
                render: (text) =>
                    <Popover content={text} title="">
                        <a>{text}</a>
                    </Popover>,
                onCell: (item) => {
                    return {
                        onClick: (event) => {
                            this.onItemClick(item.binary_uuid)
                        }
                    }
                }
            },
            {
                title: FileSize,
                dataIndex: 'size',
                key: 'size',
                render: (size) => {
                    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
                    const threshold = 1024;
                    let index = 0;

                    while (size >= threshold && index < units.length) {
                        size /= threshold;
                        index++;
                    }
                    return (<span>{ Math.round(size * 10)/10}{units[index]}</span>);
                },
                onCell: (item) => {
                    return {
                        onClick: (event) => {
                            this.onItemClick(item.binary_uuid)
                        }
                    }
                }
            },
            {
                title: UploadTime,
                dataIndex: 'time',
                key: 'time',
                render: (timestamp) => {
                    const date = new Date(timestamp);
                    const formattedDate = date.toLocaleDateString();
                    return <span>{formattedDate}</span>;
                },
                onCell: (item) => {
                    return {
                        onClick: (event) => {
                            this.onItemClick(item.binary_uuid)
                        }
                    }
                }
            },
            {
                title: Action,
                key: 'action',
                render: (_,record) => {
                    let url = config.baseUrl + '/files/' + userInfo.userId + '/' + record.file;
                    return (<Space size="middle">
                                <a href={url}>{Download}</a>
                            </Space>)
                }
            }
        ];
        const {items} = this.state
        const UploadFile = this.state.projectLanguage === "en" ? "Click to Upload File" : "点击上传文件"
        const ErJinZhi = this.state.projectLanguage === "en" ? "Please upload x86/x64/ARM32/AArch64/PPC32/PPC64 PE/MACHO/ELF file, and file size limited to 10M." : "请上传x86/x64/ARM32/AArch64/PPC32/PPC64 PE/MACHO/ELF文件，文件大小限制在10M以内。"
        const RecentlyList = this.state.projectLanguage === "en" ? "List of recently uploaded files" : "最近上传的文件列表"

        return (
            <div>
                <div align={'center'} style={{marginTop: 54, marginBottom: 20}}>
                    <div style={{marginTop: 12, marginBottom: 12+20}}><LogoSvg /></div>
                    <Upload {...props}>
                        <Button type="primary" icon={<UploadOutlined />}>{UploadFile}</Button>
                        <div style={{marginTop: 16}}><Text>{ErJinZhi}</Text></div>
                    </Upload>

                </div>
                <div>
                    <div style={{marginTop: 4, marginBottom: 16,fontSize:16}}>{RecentlyList}</div>
                    <Table dataSource={items} columns={columns} pagination={false}/>
                </div>
            </div>
        );
    }
}

export default FileUploader;
