import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, theme } from "antd";
import {GuardProvider} from "@authing/guard-react18";
// import '@authing/guard-react18/dist/esm/guard.min.css'
import './guard.css'
const ThemeData = {
  borderRadius: 6,
  colorPrimary: '#1677ff',
  // Button: {
  //   colorPrimary: '#00B96B',
  // },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
    <ConfigProvider theme={{
      algorithm: theme.darkAlgorithm,
      token: {
        "colorPrimary": "#00ff25",
        "colorInfo": "#00ff25",
        "colorBgBase": "#1c2023",
        "colorLink": "#51a1ff",
        "sizeStep": 4,
        "wireframe": true,
        "borderRadius": 4,
        "colorPrimaryActive": "#36ff53",
        "colorText": "#ffffff",
        "colorBgElevated": "#1c2023"
      },
      components: {
        "primaryShadow": "none",
        "Input": {
          "borderRadius": 0,
          "colorPrimaryHover": "#58626e",
          "colorPrimaryActive": "#58626e",
          "colorPrimary": "#58626e",
          "colorTextPlaceholder": "rgb(56, 66, 73)"
        },
        "Tooltip": {
          "colorText": "rgb(227, 231, 238)",
          "colorBgSpotlight": "rgb(66, 66, 66)"
        },
        "Tabs": {
          "colorPrimaryHover": "rgb(227, 231, 238)",
          "colorText": "rgb(148, 151, 164)",
          "borderRadius": 0,
          "borderRadiusLG": 0,
          "colorPrimaryActive": "rgb(227, 231, 238)",
          "colorPrimary": "rgb(227, 231, 238)",
          "colorBgContainer": "rgb(50, 62, 68)",
          "colorBorder": "rgba(255, 255, 255, 0)",
          "colorBorderSecondary": "rgba(14, 16, 18, 0)",
          "cardBg": "rgb(31, 38, 41)",
          "itemColor": "rgb(134, 156, 176)"
        }
      },
    }}>
      <GuardProvider
          appId="656d504223932cbf29f71a19"
          config={{
            loadingComponent: (
              <img src="/auth-loading.gif" />
            )
          }}
          mode="modal">
        <Router>
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/analysis/" element={<App />} />

          </Routes>
        </Router>
      </GuardProvider>
    </ConfigProvider>

 // </React.StrictMode>
);

reportWebVitals();
